#about-me{
    background: linear-gradient(
      to right,
      var(--background-blue) 0%,
      var(--background-blue) 40%,
      var(--medium-blue) 45%,
      var(--medium-blue) 100%
    );
  min-height: 1000px;
  padding-top: 100px;
  padding-left: 40px;
  grid-template-columns: 1.5fr 2fr;
  grid-template-rows: 100px auto 1fr;
  column-gap: clamp(5px, 3vw, 50px); 
  row-gap: 20px;
  grid-template-areas: 
            "img name"
            "img desc"
            "tools desc";
  
  & > img{
    padding-bottom: 15px;
    width: clamp(250px, 40vw, 600px);
    grid-area: img;
    align-self: start;
    justify-self: start;
    border-radius: 2rem;
  }

  & > h1{
    grid-area: name;
    justify-self: start;
    text-align: start;
  }
   .text{
    padding: 15px;
    grid-area: desc;
    justify-self: start;
    font-size: 1.1rem;
    font-family: 'futura' 'sans-serif';
    line-height: 2rem;
    text-indent: 4rem;
  }
  h2{
    font-size: 1.5rem;
  }
  a {
    color: var(--english-violet-light);
  }
  .tools{
    grid-area: tools;
    height: 100%;
  }
  ul {
    display: grid;
    grid-template-rows: 30px auto 30px auto 1fr;
    gap: 5px;
    padding: 20px;
    align-self: flex-start;
    justify-content: center;
    margin-bottom: 50px;
    background-color: var(--background-blue);
    border: 3px solid var(--light-blue);
    border-radius: 30px;
    li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 5px;
      .icon{
        height: 0px;
      }
      .icon:not(:last-child){
        margin-right: -5px;
        border-right: 2px solid var(--light-blue);
      }
    }
    i{
      font-size: 10rem;
    }
    img{
      width: 32px;
    }

  }
};

#express{
  background-color: white;
  border-radius: 100%;
  padding: 3px;
}

@media (max-width: 700px) {
  #about-me{
    min-height: 1400px;
    background: linear-gradient(
      to top,
      var(--background-blue) 0%,
      var(--background-blue) 20%,
      var(--medium-blue) 25%,
      var(--medium-blue) 100%
    );
    padding-top: 90px;
    grid-template-columns: 1fr;
    grid-template-rows: 100px auto 1fr auto;
    gap: 15px;
    grid-template-areas: 
                        "name"
                        "img"
                        "desc"
                        "tools";
  h1{
    text-align: center;
  }
  p{
    font-size: 1.3rem;
  }
  img{
    align-self: center;
    justify-self: center;
  }

  .tools{
    .icon{
      width: 0px;
    }
  }
}
}