@import './reset.css';
@import './animations.scss';

@font-face {
  font-family: "gobold";
  src: local("gobold_lowplus-webfont"),
    url("../fonts/gobold_lowplus-webfont.woff") format("woff");
}

@font-face {
  font-family: "futura";
  src: local("futurabookbt-webfont"),
    url("../fonts/futurabookbt-webfont.woff") format("woff");
}

*{
  // outline: 1px dashed red;
}

h1, h2{
  font-family: 'gobold', 'serif';
  letter-spacing: 2px;
}

:root {
  color: white;
  font-size: min(clamp(13px, 2vw, 16px), clamp(13px, 2vh, 16px));
  font-family: 'futura', serif;
}

.App{
  background-color: var(--background-blue);
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
}

a {
  text-decoration-line: none;
}

button {
  border: none;
  border-radius: 16px;
  padding: 5px 10px 5px 10px;
  font-size: 1.2rem;
  outline: 3px solid $english-violet;
}

section,
main{
  min-height: 100vh;
  position: relative;
  padding: 20px;
  display: grid;
  align-items: center;
  justify-items: center;
  *:not(span){
    animation: fade-in 1s ease-in;
  }

  h1{
    font-size: 4rem;
    width: 100%; 
    text-align: center; 
  } 

}

.circle {
  background: $light-blue;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
}


.text-pop-up-top {
	-webkit-animation: text-pop-up-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: text-pop-up-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.swiper {
  width: 100%;
  height: auto;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  width: auto;
  height: auto;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 0px;
    content: url('../assets/arrow_icon.svg');
    z-index: 100;
  }
  .swiper-button-prev:after {
    transform: rotate(180deg);
  }

.swiper-button-next,
.swiper-button-prev {
  border-radius: 200%;
  height: clamp(32px, 6vw, 48px);
  width: clamp(32px, 6vw, 48px);
  background-color: var(--light-purple);
  color: var(--background-blue);
  font-size: 12px;
  z-index: 100;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-container-vertical > .swiper-pagination {
  right: 10px;
  top: 50%;
  -moz-transform: translate3d(0px, -50%, 1px);
  -webkit-transform: translate3d(0px, -50%, 1px);
  transform: translate3d(0px, -50%, 1px);
}

@media (max-width: 500px){
  :root{
    font-size: 11px;
  }
}
