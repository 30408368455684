.App > header {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  gap: 30px;
  font-weight: bold;
  background-image: url('../assets/fondo.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  h1, h2{
    letter-spacing: 0.1rem;
  }
  h1{
    font-size: clamp(3rem, 6vw, 5rem);
    -webkit-animation: text-pop-up-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: text-pop-up-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  h2{
    font-size: clamp(2rem, 4vw, 3rem);
    color: var(--light-purple);
    -webkit-animation: text-pop-up-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: text-pop-up-top 2s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  span {
    animation: bounce-in-bottom 2s 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
}
