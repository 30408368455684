:root {
  /* CSS HEX */
  --skobeloff: #006466ff;
  --skobeloff-light: rgb(9, 214, 218);
  --midnight-green-eagle-green: #065a60ff;
  --midnight-green-eagle-green-2: #0b525bff;
  --midnight-green-eagle-green-3: #144552ff;
  --charcoal: #1b3a4bff;
  --prussian-blue: #212f45ff;
  --prussian-blue-light: rgb(47, 69, 104);
  --space-cadet: #272640ff;
  --dark-purple: #312244ff;
  --dark-purple-2: #3e1f47ff;
  --dark-purple-2-opaque: rgba(62, 31, 71, 0.5);
  --english-violet: #654c6cff;
  --english-violet-light: rgb(205, 163, 216);

  --background-blue: rgb(3 11 29);
  --medium-blue: rgb(0 20 65);;
  --light-blue: rgb(80 138 238);
  --light-purple: rgb(134 107 236);
}


/* SCSS RGB */
$skobeloff: rgba(0, 100, 102, 1);
$midnight-green-eagle-green: rgba(6, 90, 96, 1);
$midnight-green-eagle-green-2: rgba(11, 82, 91, 1);
$midnight-green-eagle-green-3: rgba(20, 69, 82, 1);
$charcoal: rgba(27, 58, 75, 1);
$prussian-blue: rgba(33, 47, 69, 1);
$space-cadet: rgba(39, 38, 64, 1);
$dark-purple: rgba(49, 34, 68, 1);
$dark-purple-2: rgba(62, 31, 71, 1);
$dark-purple-2-opaque: rgba(62, 31, 71, 0.5);
$english-violet: rgba(101, 76, 108, 1);

$background-blue: rgb(3 11 29);
$light-blue: rgb(80 138 238);
$medium-blue: rgb(0 20 65);;
$light-purple: rgb(134 107 236);


/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #006466ff, #065a60ff, #0b525bff, #144552ff, #1b3a4bff, #212f45ff, #272640ff, #312244ff, #3e1f47ff, #654c6cff);
$gradient-right: linear-gradient(90deg, #006466ff, #065a60ff, #0b525bff, #144552ff, #1b3a4bff, #212f45ff, #272640ff, #312244ff, #3e1f47ff, #654c6cff);
$gradient-bottom: linear-gradient(180deg, #006466ff, #065a60ff, #0b525bff, #144552ff, #1b3a4bff, #212f45ff, #272640ff, #312244ff, #3e1f47ff, #654c6cff);
$gradient-left: linear-gradient(270deg, #006466ff, #065a60ff, #0b525bff, #144552ff, #1b3a4bff, #212f45ff, #272640ff, #312244ff, #3e1f47ff, #654c6cff);
$gradient-top-right: linear-gradient(45deg, #006466ff, #065a60ff, #0b525bff, #144552ff, #1b3a4bff, #212f45ff, #272640ff, #312244ff, #3e1f47ff, #654c6cff);
$gradient-bottom-right: linear-gradient(135deg, #006466ff, #065a60ff, #0b525bff, #144552ff, #1b3a4bff, #212f45ff, #272640ff, #312244ff, #3e1f47ff, #654c6cff);
$gradient-top-left: linear-gradient(225deg, #006466ff, #065a60ff, #0b525bff, #144552ff, #1b3a4bff, #212f45ff, #272640ff, #312244ff, #3e1f47ff, #654c6cff);
$gradient-bottom-left: linear-gradient(315deg, #006466ff, #065a60ff, #0b525bff, #144552ff, #1b3a4bff, #212f45ff, #272640ff, #312244ff, #3e1f47ff, #654c6cff);
$gradient-radial: radial-gradient(#006466ff, #065a60ff, #0b525bff, #144552ff, #1b3a4bff, #212f45ff, #272640ff, #312244ff, #3e1f47ff, #654c6cff);
