#contact {
  background-color: var(--medium-blue);
  h1 {
    padding-bottom: 10px;
    border-bottom: 3px solid var(--light-blue);
    align-self: end;
    z-index: 1;
  }
  & > div {
    display: flex;
    gap: 25px;
    justify-self: stretch;
    justify-content: space-evenly;
    img{
      width: clamp(48px, 15vw, 126px);
    }
  }

  .arrow {
    position: absolute;
    top: 0px;
    left: 50%;
    z-index: 0;
    width: auto; 
    transform: translate(-50%);
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
    border-top: 20vh solid var(--background-blue);
  }


  .icon {
    opacity: 1;
    * {
      font-size: 1.5rem;
    }

  }

  .icon:first-child{
    animation: bounce-in-bottom 2s ;
  }
  .icon:nth-child(2){
    animation: bounce-in-bottom 2.5s ;
  }
  .icon:nth-child(3){
    animation: bounce-in-bottom 3s ;
  }
}