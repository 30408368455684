@import './appStyle.scss';

nav{
  position: relative;
  background-color: $background-blue;
  background-image: url('../assets/fondo.jpg');
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: sticky;
  top: 0px;
  min-height: 70px;
  z-index: 10;
  ul{
    border-top: 3px solid $light-blue;
    display: flex;
    justify-content: center;
    padding: 14px;
    width: calc(100vw - 80px);
    li:first-child{
      a{
        margin-left: 40px;
        border-right: 3px solid $light-blue;
      }
    }
    li:last-child{
      a{
        border-left: 3px solid $light-blue;
      }
    }
  }
  a{
    padding: 12px;
    color: white;
    font-size: 20px;
    &:hover{
      background-color: $light-blue;
    }
  }
  .circle{
    position: absolute;
    top: 13px;
    right: 30px;
  }
}



@media (max-width: 500px){
  nav {
  min-height: 55px;
  ul{
    padding: 10px;
    width: calc(100vw - 60px);
    li:first-child{
      a{
        margin-left: 30px;
      }
    }
  }
  a{
    padding: 8px;
    font-size: 15px;
  }
  .circle{
    top: 11px;
    right: 30px;
  }
}
}
