#projects{
  height: 100vh;
  h1{
    align-self: flex-end;
  }
  .buttons{
    align-self: start;
  }
}

.project {
  display: grid;
  border-radius: 36px;
  border: 3px solid var(--light-blue);
  box-shadow: 3px 3px 7px black;
  padding: 10px;
  width: min(clamp(230px, 40vw, 430px), clamp(230px, 50vh,430px));
  grid-template-columns: 1fr;
  grid-template-areas: 
                      "gif"
                      "title"
                      "stack";
  align-items: center;
  justify-items: center;
  gap: 1.5rem;

  & > img{
    grid-area: gif;
    border-radius: 26px;
    width: 100%;
  }
  & > h2 {
    grid-area: title;
    font-size: 2rem;
  }


  & > .stack{
    grid-area: stack;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .icon{
      padding: 8px;
      .tooltip{
        top: -25px;
      }
    }
    img{
      width: min(clamp(18px, 4vw, 24px), clamp(18px, 4vh, 24px));
    }
  }
}
